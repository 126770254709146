import { $ } from '../utils';
const chengeBuyLink = () => {
    const baseLink = '/emarket/basket/put/element/';
    const buyBtn = $('#buyBtn');
    const prodId = buyBtn.dataset.prodid;
    const amount = $('[data-element="field_number"]').dataset.amount;
    const props = $('[data-element="option_props"]');
    const radios = props.querySelectorAll('input');
    let propLink;
    radios.forEach((radio) => {
        if (radio.checked) {
            console.log(radio);
            propLink = `options[${radio.name}]=${radio.id}`;
        }
    });
    const resultLink = `${baseLink}${prodId}/?amount=${amount}&${propLink}&`;
    buyBtn.pathname = resultLink;
};
export default chengeBuyLink;
