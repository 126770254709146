import { $$ } from '../utils';
import { addTextToFormInput } from '../forms';
const startCounters = (fieldSelector) => {
    const fields = $$(fieldSelector);
    if (fields.length === 0)
        return false;
    addTextToFormInput('[name = "data[new][count]"]', '1');
    fields.forEach((field) => field.addEventListener('click', startCounter));
};
const startCounter = ({ target }) => {
    if (target.classList.contains('btn') || target.tagName === 'path' || target.tagName === 'svg' || target.tagName === 'I') {
        const wrapper = target.closest('[data-element="field_number"]');
        const button = target.closest('.btn');
        const input = wrapper.querySelector('[data-element="input"]');
        let culc = Number(input.value) + Number(button.dataset.value);
        if (culc < Number(input.min) || culc > Number(input.max)) {
            return true;
        }
        const culcText = culc.toString();
        input.value = culcText;
        wrapper.dataset.amount = culcText;
        addTextToFormInput('[name = "data[new][count]"]', culcText);
        // if(wrapper.dataset.type === 'onPage'){
        //     changeProductPrice();
        // } else {
        //     chengeItemAmount({target: input})
        // }
    }
};
export default startCounters;
