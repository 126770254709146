import { FormValidate } from './formValidate';
// import {getCoords} from '../utils';
class StepsForm {
    constructor(props) {
        this.showElt = ($elt) => $elt.classList.remove('hide');
        this.hideElt = ($elt) => $elt.classList.add('hide');
        this.showCurrentStep = () => {
            this.steps.forEach((step, index) => {
                index === this.state.current ? this.showElt(step) : this.hideElt(step);
                this.state.current === this.state.amount - 1 ? this.hideElt(this.nextBtn) : this.showElt(this.nextBtn);
                if (this.prevBtn)
                    this.state.current === 0 ? this.hideElt(this.prevBtn) : this.showElt(this.prevBtn);
            });
        };
        this.validateCurrentStep = () => {
            const inputs = this.steps[this.state.current].querySelectorAll('.required');
            this.formValidate.checkInputs(inputs);
            const errorField = this.steps[this.state.current].querySelector('.error');
            if (errorField) {
                this.state.currentStatus = 'invalid';
                // window.scrollTo( 0, (getCoords(errorField).top+30) );
            }
            else {
                this.state.currentStatus = 'valid';
            }
        };
        this.nextBtnHandler = (event) => {
            event.preventDefault();
            this.validateCurrentStep();
            if (this.state.currentStatus === 'valid') {
                this.state.current = ++this.state.current;
                this.showCurrentStep();
            }
        };
        this.prevBtnHandler = (event) => {
            event.preventDefault();
            this.state.current = --this.state.current;
            this.showCurrentStep();
        };
        this.init = () => {
            this.showCurrentStep();
            this.nextBtn && this.nextBtn.addEventListener('click', this.nextBtnHandler);
            this.prevBtn && this.prevBtn.addEventListener('click', this.prevBtnHandler);
        };
        this.form = document.querySelector(props.formSelector);
        this.steps = this.form.querySelectorAll(props.stepsSelector);
        this.nextBtn = this.form.querySelector(props.nextBtnSelector);
        this.prevBtn = this.form.querySelector(props.prevBtnSelector);
        this.state = {
            current: 0,
            amount: this.steps.length,
            currentStatus: 'not verified'
        };
        this.formValidate = new FormValidate({
            form: this.form,
            inputErrorTextSelector: '.field__error-text',
            formErrorTextSelector: '.form__error-text',
            botErrorTextSelector: '.form__error-bot-text'
        });
    }
}
export default StepsForm;
