class AntiSpam {
    constructor(props) {
        this.addIsRobotCheckInput = (form) => {
            const checkInput = document.createElement('input');
            checkInput.setAttribute('type', 'hidden');
            checkInput.setAttribute('class', 'isRobotInput');
            checkInput.setAttribute('name', 'isRobot');
            checkInput.setAttribute('value', '');
            form.appendChild(checkInput);
        };
        this.antiSpamHandler = () => {
            const isRobotInput = this.form.querySelector('.isRobotInput');
            isRobotInput.value = 'false';
        };
        this.init = () => {
            this.addIsRobotCheckInput(this.form);
            this.form.addEventListener('click', this.antiSpamHandler, false);
        };
        this.form = props.form;
    }
}
export default AntiSpam;
