import { $ } from '../utils';
import { changeProductPrice } from './index';
const propsSwicher = (propsWrapper) => {
    const props = $(propsWrapper);
    if (!props)
        return false;
    const radios = props.querySelectorAll('input');
    radios.forEach((radio) => {
        radio.addEventListener('change', changeProductPrice);
    });
};
export default propsSwicher;
