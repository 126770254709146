import { $ } from '../utils';
import { chengeBuyLink } from './index';
const changeProductPrice = () => {
    const amount = $('[data-element="field_number"]').dataset.amount;
    const actualPrice = $('[data-element="actual_price"]');
    const props = $('[data-element="option_props"]');
    const radios = props.querySelectorAll('input');
    let price;
    radios.forEach((radio) => {
        if (radio.checked) {
            price = radio.dataset.price;
        }
    });
    let culc = parseInt(amount) * parseInt(price);
    actualPrice.innerHTML = culc.toString();
    chengeBuyLink();
};
export default changeProductPrice;
