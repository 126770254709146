import { $ } from '../utils';

const eMarketSaveInfo = (formSelector, btnSelector) => {
    const form = $(formSelector);
    const formBtn = $(btnSelector);

    if (!form) return false;

    const inputs = form.querySelectorAll('input');

    const loadInfo = () => Object.values(inputs).map(input => {
        const name = input.name;
        const storValue = localStorage.getItem(input.name);
        if (storValue !== '') {
            if (storValue != null) {
                input.value = localStorage.getItem(input.name);
            }
        }
    });
    const saveInfo = () => Object.values(inputs).map(input => input.value && localStorage.setItem(input.name, input.value.toString()));
    
    loadInfo();
    formBtn.on('click', saveInfo);
}

export default eMarketSaveInfo;