import { addTextToFormInput } from '../forms';
const productOrderSum = () => {
    const props = document.querySelector('[data-element="option_props"]');
    if (!props)
        return false;
    const prodArticle = document.querySelector('.product__articul');
    console.log(prodArticle);
    const showOrderInfoAtProdPage = () => {
        const propsData = collectPropsData(props);
        const prodName = prodArticle ? prodArticle.innerText : '';
        const orderText = collectOrderText(prodName, propsData);
        addOrderTextToPage(orderText);
        addTextToFormInput('[name = "data[new][order]"]', orderText.orderDesc);
    };
    props.addEventListener('input', showOrderInfoAtProdPage);
    showOrderInfoAtProdPage();
};
const collectPropsData = (props) => {
    const propsData = [];
    const radios = props.querySelectorAll('input');
    radios.forEach((radio) => {
        if (radio.checked) {
            const radioData = {
                title: radio.dataset.title ? radio.dataset.title : '',
                sign: radio.dataset.sign,
                value: radio.dataset.value,
                name: radio.dataset.name,
            };
            propsData.push(radioData);
        }
    });
    return propsData;
};
const addOrderTextToPage = (data) => {
    const summary = document.querySelector('[data-component="product-order-sum"]');
    if (!summary)
        return false;
    summary.classList.remove('hide');
    const sumName = summary.querySelector('[data-element="orderName"]');
    const sumProps = summary.querySelector('[data-element="orderDesc"]');
    sumName.innerHTML = data.orderName;
    sumProps.innerHTML = data.orderDesc;
};
const collectOrderText = (prodName, props) => {
    let orderName = prodName;
    let orderDesc = '';
    props.forEach((prop) => {
        let propName = '';
        let propNameExt = '';
        if (prop.sign !== '') {
            propName += prop.sign;
            propNameExt += `${prop.sign} – ${prop.value}`;
        }
        else {
            switch (prop.name) {
                case 'Да':
                    propName += prop.title;
                    break;
                case 'Нет':
                    break;
                default:
                    propName += prop.name;
                    break;
            }
            propNameExt += prop.name;
        }
        orderName += ` ${propName}`;
        orderDesc += `<span>${prop.title}:&nbsp;<span class="ff-bold">${propNameExt}</span></span>;&nbsp;&nbsp;&nbsp;`;
    });
    return {
        orderName,
        orderDesc
    };
};
export default productOrderSum;
