// Опции цены и количество товара
// Функция добавляет в корзину товар состраницы товара с выбранными опциями и количеством товара
// 1 - селектор кнопки "в корзину"
// 2 - селектор полей с опциями (input type=radio)
// 3 - cелектор поля для ввода количества товара (input)

const putElement = (selector, props, count) => {
    const putBtn = document.querySelector(selector);
    
    const put = function (e) {
        const impOption = document.querySelectorAll(props); // Поля выбора опций
        const impCount = document.querySelector(count); // Поле для ввода количество
        const pageId = impCount.dataset.pageid;

        let putStr = '\u003F'; //Знак вопроса
    
        if (impCount.value !== '') {
            const amountStr = 'amount=' + impCount.value + '&';
            putStr += amountStr;
        }
    
        let i = impOption.length;
        while (i--) {
            if (impOption[i].checked) {
                const optionStr = impOption[i].dataset.value + '&'; // Если в конце строки не будет стоять '&' товар не будет добавляться
                putStr += optionStr;
            }
        }
    
        putBtn.pathname = '/emarket/basket/put/element/' + pageId + '/' + putStr;
    };

    if (putBtn) putBtn.addEventListener('click', put, false);
}


// -------------------

// Изменение количества товаров в корзине

const chengeAmount = (selector) => {
    const basketCart = document.querySelector(selector);
    if (basketCart) basketCart.addEventListener('change', chengeItemAmount, false);
}

const chengeItemAmount = ({ target }) => {
    console.log(target);
    const itemId = target.dataset.id; // id товара
    const itemOptions = target.dataset.options; // Опции товара
    const value = target.value; // Новое количество товара
    const link = `/emarket/basket/put/element/${itemId}/?amount=${value}&${itemOptions}`;
    const spinner = document.querySelector(`.${target.dataset.spinner}`); // загрузчик
    spinner.style.display = 'block';
    document.location.href = link;
}

// -------------------

export {
    putElement,
    chengeAmount,
    chengeItemAmount
}
